import React from 'react'

import Layout from "../templates/Layout"
import { Hero, Container, Row, } from "@edinburghairport/runway"
import { Link } from "gatsby"
import '../scss/main.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class ConsultationPage extends React.Component {  
  render() {

    const content = {
      background: {
        file: {
          url: "https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/consultation_bg.png"
        }
      },
      heading: "Previous Consultation Material",
    };

    return (
      <Layout>
        <Hero content={content} />
        <div className="section section__consult">
          <Container>
            <Row>
              <div className="col-12">
                <p className="breadcrumb"><Link href="/">Home</Link> > Consultation Materials</p>
              </div>
            </Row>
            <Row>
              
                <div className="col-md-12">
                <div className="section__consult--intro">
                  <p>Edinburgh Airport ran an Airspace Change Programme from 2015-2018, which included three public consultations. The feedback from these consultations informed their application for Application for Airspace Change in August 2018. The CAA rejected this Application for Airspace Change in November 2018.</p>
                </div>
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <h4>Application for airspace change</h4>
                <ul className="section__consult--applicationchange">
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/V2ApplicationDocument.pdf">Technical Application for Airspace Change V2.0</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/EDI_ACP_Rationale_Document.pdf">Our Airspace Change Proposal V1.0</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/20171004_Environmental_Assessment.pdf">Environment Assessment for Airspace Change Proposal V1.0</a></li>
                </ul>
                <h4>Material from previous consultations</h4>
                <ul>
                  <li className="ul-header">Consultation 1 (June  – September 2016)</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/library/Edinburgh_Airport_Initial_Consultation_Book.pdf">Consultation 1 - Consultation book</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/library/Edinburgh_Airport_Initial_Consultation_Findings_Report.pdf">Consultation 1 - Findings report</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=rRG8ILJBUPc">Consultation 1 - video</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Privacy+Policy/ACP+-+Privacy+Policy+-+Version+1.3+(Consultations+1+and+2).pdf">Airspace Change Privacy Policy – Consultations 1 and 2</a></li>
                </ul>
                <ul>
                  <li className="ul-header">Consultation 2 (January – May 2017)</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/ACP_consultation_book.pdf">Consultation 2 - Consultation book</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/library/Easy_Read_Airspace_Change_Consultation_Book.pdf">Consultation 2 Consultation book easy read version</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/library/Flight_path_maps.pdf">Consultation 2 Flight path maps</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/library/Route_Option_Design.pdf">Consultation 2 Route options design</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/2017/07/acp/2_ACP_second_consultation_report_18072017.pdf">Consultation 2 - Findings report</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Privacy+Policy/ACP+-+Privacy+Policy+-+Version+1.3+(Consultations+1+and+2).pdf">Airspace Change Privacy Policy – Consultation 1 and 2</a></li>
                </ul>
                <ul>
                  <li className="ul-header">Supplementary Consultation (May – June 2018)</li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/consultation-material/201808_ACP_Supplementary_Report.pdf">Supplementary Consultation - ACP Supplementary Consultation Report</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/maps/Map+1+Current+flight+path.pdf">Supplementary Consultation - Current flight path and tracks GOSAM1D</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/maps/Map+2+Route+options+for+flight+path+E+as+included+in+Consultation+2.pdf">Supplementary Consultation - Proposed flight path E7a</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/maps/Map+3+Proposed+flight+path+E7a.pdf">Supplementary Consultation - Overlay of proposed flight path E7a to current flight path tracks</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/maps/Map+4+Overlay+of+maps+1%2C+2+and+3.pdf">Supplementary Consultation - Overlay of maps 1, 2 and 3</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/maps/Map+5+Flight+simulation+results+for+E7a.pdf">Supplementary Consultation - Flight simulation results for E7a</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Consultation+material/EIA_Aug_2018.pdf">Supplementary Consultation - Environmental Impact Assessment</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Privacy+Policy/ACP+-+Privacy+Policy+-+Supplementary+Consultation+FINAL+-+22.5.18.pdf">Supplementary Consultation - Airspace Change Privacy Policy – Supplementary Consultation</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Consultation+material/CAA+ERCD+-+revised+RWY06+SIDs.pdf">Supplementary Consultation - CAA ERCD - revised SIDs</a></li>
                  <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Consultation+material/Supplementary+consultation+booklet.pdf">Supplementary Consultation - Supplementary consultation booklet</a></li>
                </ul>
                <h4>Previous privacy notices</h4>
                <ul>
                <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Privacy+Policy/ACP+-+Privacy+Policy+-+Supplementary+Consultation+FINAL+-+22.5.18.pdf">Airspace Change Privacy Policy – Supplementary Consultation</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://s3-eu-west-1.amazonaws.com/edinburghairport/files/acpv2/2018/Privacy+Policy/ACP+-+Privacy+Policy+-+Version+1.3+(Consultations+1+and+2).pdf">Airspace Change Privacy Policy – Consultations 1 and 2</a></li>
                </ul>
              </div>
            </Row>

          </Container>
        </div>
      </Layout>
    )
  }
}

export default ConsultationPage